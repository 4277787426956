import styled from "@emotion/styled"
import { Box, Flex } from "@theme-ui/components"
import React from "react"
import RichText from "./text/rich-text"

const TextContainer = styled.div`
  width: 100%;
  max-width: 850px;

  p {
    white-space: pre-wrap;
  }

  h3 {
    font-size: 10px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 400;
  }
`

const OpenPosition = ({ data, padTop, padBottom }) => {
  return (
    <Box
      className="open-position"
      sx={{
        maxWidth: "1200px",
        justifyContent: "center",
        width: "100%",
      }}
      mx={"auto"}
      px={[3, "40px"]}
      pt={padTop ? [5, 6] : [2, 3]}
      pb={padBottom ? [5, 6] : [2, 3]}
    >
      <Flex
        sx={{
          flexDirection: "column",
          paddingTop: "20px",
          position: "relative",
          gap: ["30px", "20px"],
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: ["flex-start", "flex-end"],
            gap: "20px",
            borderTop: "1px solid black",
          }}
        >
          <Box
            pt={"1px"}
            as={"h2"}
            sx={{
              fontWeight: "300",
              fontSize: ["17px", "20px"],
              lineHeight: ["30px"],
            }}
          >
            {data.title}
          </Box>
          <Box
            as={"a"}
            target="_blank"
            href={`mailto:${data.email}?subject=${data.emailSubject}`}
            sx={{
              fontFamily: "Formular",
              fontWeight: "700",
              textTransform: "uppercase",
              fontSize: ["7px", "10px"],
              lineHeight: ["12px"],
              backgroundColor: "black",
              padding: ["10px 23px", "19px 44px"],
              color: "white",
              textDecoration: "none",
            }}
          >
            Submit here
          </Box>
        </Flex>
        <Box
          className="open-position-description"
          sx={{
            width: "100%",
            fontSize: "10px",
            lineHeight: "12px",
            maxWidth: "700px"
          }}
        >
          <RichText html={data.description.childMarkdownRemark.html} />
        </Box>
      </Flex>
    </Box>
  )
}

export default OpenPosition
